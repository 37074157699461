import React, { useState, useEffect, useCallback } from "react";
import OnneRadioButton from "./OnneRadioButton";
import { Autocomplete, TextField, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Divider, Button, Select, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../features/onneMappingFilterSlice'; 
import { useNavigate } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import toolsMappingApi from "../../tools/toolsMappingApi";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function OnneListFilters({previewGtin}) {
    const { getSourceItems } = toolsMappingApi();
    const dmFieldsData = useSelector((state) => state.dmFieldsData.value);
    const selectedDataModelStorage = useSelector((state) => state.selectedDataModel.value);
    const [trascendingPercentage, setTrascendingPercentage] = useState(0);
    const [brickPercentage, setBrickPercentage] = useState(0);
    const dispatch = useDispatch();
    const [mappedState, setMappedState] = useState(0); //all
    const [mappedType, setMappedType] = useState(0); //all
    const [dataType, setDataType] = useState(0); //all
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/reports', {replace: true}), [navigate]);
    const [classLabel, setClassLabel] = useState("Classification");
    const [previewOptions, setPreviewOptions] = useState([]);
    const gpc = useSelector((state) => state.gpc.value);

    useEffect(() => {
        if(selectedDataModelStorage !== undefined && selectedDataModelStorage.type !== undefined){
            if(selectedDataModelStorage.type === 'GS1'){
                setClassLabel("Brick")
            }

            if(selectedDataModelStorage.version !== undefined) {
                getSourceItems({gpcCode: gpc, gtins: true}).then(data => {
                    if(!data.error) {
                        setPreviewOptions(data.payload);
                    }
                })
            }
        }
        
    }, [selectedDataModelStorage])

    useEffect(() => {
        dispatch(setFilters({mappedState: mappedState, dataType: dataType, mappedType: mappedType}));
    }, [mappedState, dataType, mappedType])

    useEffect(() => {
        if(dmFieldsData.TrascendingTotal !== undefined && dmFieldsData.TrascendingTotal !== 0 && dmFieldsData.TrascendingComplete !== undefined) {
            setTrascendingPercentage((dmFieldsData.TrascendingComplete*100)/dmFieldsData.TrascendingTotal)
        }

        if(dmFieldsData.BrickTotal !== undefined && dmFieldsData.BrickTotal !== 0 && dmFieldsData.BrickComplete !== undefined) {
            setBrickPercentage((dmFieldsData.BrickComplete*100)/dmFieldsData.BrickTotal)
        }
    }, [dmFieldsData])

    const FormStyled = styled(FormControl)(() => ({
        marginLeft: '20px'
    }));

    const RadioGroupStyled = styled(RadioGroup)(() => ({
        marginLeft: '0px'
    }));

    const ButtonStyled = styled(Button)(() => ({
        paddingTop: '20px',
        marginLeft: '20px'
    }));

    const handlePreviewGtinChange = (gtin) => {
        if(gtin !== null && gtin !== undefined) {
            navigate("/mapping/" + gtin.id)
        }else {
            navigate("/mapping")
        }
    }

    return  <Grid container item direction="row" width="100%" height="100%">
                <Grid item container xs={2} rowSpacing={1} paddingRight={"15px"} direction="column">
                    <Grid item>
                        <FormLabel>Preview GTIN</FormLabel>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            disablePortal
                            clearOnBlur
                            fullWidth
                            value={previewGtin}
                            onChange={(e, newValue) => handlePreviewGtinChange(newValue)}
                            //value={mapping !== undefined ? mapping[row.targetField] !== undefined ? mapping[row.targetField] : '' : ''}
                            options={
                                previewOptions.map(pGtin => {
                                    return {label: pGtin, id:pGtin}
                                })
                            }
                            sx={{ backgroundColor: "white" }}
                            ListboxProps={{ style: { maxHeight: 130 } }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    placeholder="Search GTIN"
                                    variant="standard" 
                                />}
                        />
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                {selectedDataModelStorage !== undefined && selectedDataModelStorage.default ?
                    <Grid item container xs={3}>
                        <Grid item  xs={12} sx={{paddingLeft: "20px"}}>
                            <FormLabel>Field Type</FormLabel>
                        </Grid>
                        <Grid item container xs={12} direction="row">
                            <Grid item xs={8}>
                                <FormStyled>
                                    <RadioGroupStyled
                                        sx={{ display: 'inline-block' }}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={dataType}
                                        onChange={(e) => setDataType(parseInt(e.target.value))}
                                    >
                                        <FormControlLabel value={0} sx={{height: "30px"}} control={<OnneRadioButton label="All" />}  />
                                        <FormControlLabel value={1} sx={{height: "30px"}} control={<OnneRadioButton percent={trascendingPercentage} label={"Core (" + dmFieldsData.TrascendingComplete + "/" + dmFieldsData.TrascendingTotal + ")"} /> }/>
                                        <FormControlLabel value={2} sx={{height: "30px"}} control={<OnneRadioButton percent={brickPercentage} label={classLabel + " (" + dmFieldsData.BrickComplete + "/" + dmFieldsData.BrickTotal + ")"} /> } />
                                    </RadioGroupStyled>
                                </FormStyled>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    ''
                }
                {selectedDataModelStorage !== undefined && selectedDataModelStorage.default ?
                    <Divider orientation="vertical" flexItem />
                :
                    ''
                }
                <Grid item xs={3}>
                    <FormStyled>
                        <FormLabel id="demo-row-radio-buttons-group-label">Mapping Status</FormLabel>
                        <RadioGroupStyled
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={mappedState}
                            onChange={(e) => setMappedState(parseInt(e.target.value))}
                        >
                            <FormControlLabel value={0} sx={{height: "30px"}} control={<OnneRadioButton label="All" />}  />
                            <FormControlLabel value={1} sx={{height: "30px"}} control={<OnneRadioButton label="Mapped" />}  />
                            <FormControlLabel value={2} sx={{height: "30px"}} control={<OnneRadioButton label="Not Mapped" />}  />
                        </RadioGroupStyled>
                    </FormStyled>
                </Grid>
                {selectedDataModelStorage !== undefined && selectedDataModelStorage.default ?
                    <Divider orientation="vertical" flexItem />
                :
                    ''
                }
                <Grid item xs={3}>
                    <FormStyled>
                        <FormLabel id="demo-row-radio-buttons-group-label">Mapping Type</FormLabel>
                        <RadioGroupStyled
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={mappedType}
                            onChange={(e) => setMappedType(parseInt(e.target.value))}
                        >
                            <FormControlLabel value={0} sx={{height: "30px"}} control={<OnneRadioButton label="All" />}  />
                            <FormControlLabel value={1} sx={{height: "30px"}} control={<OnneRadioButton label="Mandatory" />}  />
                            <FormControlLabel value={2} sx={{height: "30px"}} control={<OnneRadioButton label="Optional" />}  />
                        </RadioGroupStyled>
                    </FormStyled>
                </Grid>                
            </Grid>
}