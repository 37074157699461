import React, { useState, useEffect } from "react";
import OnneCheckButton from "../OnneList/OnneCheckButton";
import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

export default function GpcSelector({field, setGpcs, selectedGpcs, selectable}) {
    const[gpcList, setGpcList] = useState([])

    useEffect(() => {
        if(field !== undefined && field.gpcs !== undefined) {
            let list = [];
            field.gpcs.map(gpc => {
                list.push({"gpc" : gpc, "desc" : ""});
            })
            setGpcList(list);
        }
    }, [field])


    const handleSelection = (ids) => {
        setGpcs(ids);
    }

    const columns = [
        {
            field: 'gpc',
            headerName: 'GPC',
            align: 'center',
        },
        {
            field: 'desc',
            headerName: 'Description',
            align: 'center',
        },
    ]

    return  <Box sx={{height: 400, width: "100%", borderRadius: "4px"}} style={{marginBottom: '20px'}}>
                <DataGridPro
                    disableColumnMenu
                    sx={{fontFamily: "Figtree"}}
                    rows={gpcList}
                    columns={columns}
                    getRowId={(r) => r.gpc}
                    selectionModel={selectedGpcs}
                    onSelectionModelChange={(ids) => {handleSelection(ids)}}
                    checkboxSelection={selectable}
                />
            </Box>
}